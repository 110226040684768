import { createRouter, createWebHistory } from 'vue-router';

import Ad from '@/views/Ad.vue';
import Admin from '@/views/Admin.vue';
import CreateTemplateConfig from '@/views/CreateTemplateConfig.vue';
import Dashboard from '@/views/Dashboard.vue';
import TemplateConfig from '@/views/TemplateConfig.vue';

const routes = [
  { path: '/', component: Dashboard },
  { path: '/ad/:adId', component: Ad, props: true },
  { path: '/admin', component: Admin },
  { path: '/admin/template-config', component: CreateTemplateConfig },
  { path: '/admin/template-config/:templateId', component: TemplateConfig, props: true },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  try {
    const response = await fetch('/api/profile');
    if (!response.ok) {
      throw new Error();
    }
  } catch (err) {
    const next = encodeURIComponent(to.fullPath);
    location.href = `/login?next=${next}`;
  }
});
