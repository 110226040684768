<!-- Intake content badge for displaying the content's current status -->
<template>
  <CBadge :text="status" :color="STATUS_TO_COLOR[status]" />
</template>

<script setup lang="ts">
import type { IntakeContent } from '@/types/content';

defineProps<{ status: IntakeContent['status'] }>();

const STATUS_TO_COLOR = {
  'draft': 'gray-200',
  'in progress': 'blue-200',
  'approved': 'green-200',
};
</script>
