import { createApp } from 'vue';

import App from './App.vue';
import citrusPlugin from './plugins/citrus-vue-3';
import { router } from './router';
// NOTE(Seth): This import and the package.json shoelace dependency
// can be removed when the Citrus plugin works with Vue 3.
import '@shoelace-style/shoelace/dist/themes/light.css';
import '@/styles/globals.scss';

const app = createApp(App);

app.use(router);
app.use(citrusPlugin);
app.mount('#app');
