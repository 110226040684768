<!--
Fixed global footer.
Adjusts its content to match the width of whatever element it is rendered into.
When mounted, automatically adds margin-bottom to the app's <main> element
so that no content is hidden behind the footer.
-->
<template>
  <FlexContainer
    :id="FOOTER_ID"
    ref="container"
    tag="footer"
    center
    justified
    class="footer"
    :style="styles"
  >
    <slot />
  </FlexContainer>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, useTemplateRef } from 'vue';

import FlexContainer from '@/components/FlexContainer.vue';
import { FOOTER_HEIGHT, FOOTER_ID } from '@/constants/layout';
import type { CSSStyles } from '@/types/utility';

const container = useTemplateRef('container');

const styles = ref<CSSStyles>({ height: `${FOOTER_HEIGHT}px` });

const handleResize = () => {
  // Sets the footer's padding such that its contents are the same width & position as its parent.
  const parentRect = container.value?.$el.parentElement.getBoundingClientRect();
  styles.value = {
    ...styles.value,
    paddingLeft: `${parentRect?.left}px`,
    paddingRight: `${window.innerWidth - parentRect?.right}px`,
  };
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
  handleResize();

  const main = document.querySelector('main') as HTMLElement;
  main.style.marginBottom = `${FOOTER_HEIGHT}px`;
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);

  const main = document.querySelector('main') as HTMLElement;
  main.style.removeProperty('margin-bottom');
});
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  z-index: 1;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  border-top: $border;
  background-color: $color-white;
}
</style>
