<!--
Container for rendering a content preview, given a template id and set of block values.
Manages fetching the preview data from the backend, and utilizes the DynamicContent component for
rendering the resulting HTML and CSS strings.
Also manages rendering the preview to a PNG image, which is used on the intake dashboard.
-->
<template>
  <SectionCard>
    <template #title>Preview</template>
    <div v-if="preview" class="preview">
      <DynamicContent :html="preview.html" :css="preview.css" />
      <!-- A second preview hidden off-screen for the purposes of generating fixed-width images -->
      <div v-if="shouldRenderForImage" aria-hidden="true" tabindex="-1">
        <DynamicContent ref="for-image" class="for-image" :html="preview.html" :css="preview.css" />
      </div>
    </div>
  </SectionCard>
</template>

<script setup lang="ts">
import domToImage from 'dom-to-image-more';
import { nextTick, ref, useTemplateRef, watch } from 'vue';

import DynamicContent from '@/components/DynamicContent.vue';
import SectionCard from '@/components/SectionCard.vue';
import { useResource } from '@/composables/useResource';
import type { AdPreview } from '@/types/content';

const props = defineProps<{ templateId: number; fields: Record<string, string> }>();

const componentForImage = useTemplateRef('for-image');
const { get, inFlight } = useResource('preview');

const preview = ref<AdPreview>();
const shouldRenderForImage = ref(false);

let fetchPreviewTimeout = 0;

const renderImage = async () => {
  shouldRenderForImage.value = true;
  await nextTick();
  const previewElement = componentForImage.value?.$el;
  if (!previewElement) {
    console.error('Missing preview element');
    return '';
  }
  const image = await domToImage.toPng(previewElement, {
    corsImg: { url: '/api/image-for-preview?url=#{cors}' },
    width: previewElement.offsetWidth,
    height: previewElement.offsetHeight,
  });
  shouldRenderForImage.value = false;
  return image;
};

const withoutEmpty = (object: Record<string, string>) =>
  Object.fromEntries(Object.entries(object).filter(([, value]) => value.trim()));

const fetchPreview = async () => {
  const fieldsToPreview = withoutEmpty(props.fields);
  preview.value = await get(props.templateId, fieldsToPreview);
};

defineExpose({ renderImage });

watch(
  [props.fields],
  async () => {
    // Fetch immediately on mount, using inFlight status to avoid double fetches.
    if (!fetchPreviewTimeout && !inFlight.value.get) {
      await fetchPreview();
      // Enable `else if` condition for post-mount changes.
      fetchPreviewTimeout = 1;
    } else if (fetchPreviewTimeout) {
      // Use a timeout to debounce requests while typing.
      clearTimeout(fetchPreviewTimeout);
      fetchPreviewTimeout = setTimeout(fetchPreview, 500);
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
.preview {
  max-width: 360px;
  margin: 24px auto 0;
}

.for-image {
  position: fixed;
  left: 100%;
  width: 280px;
}
</style>
