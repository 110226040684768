<!-- Wrapper element for handling dropped files. Emits an event with the file data on drop -->
<template>
  <div
    :class="{ 'drop-zone': true, 'active': isDragActive }"
    @dragover.prevent.stop="isDragActive = true"
    @dragenter.prevent.stop="isDragActive = true"
    @dragend.prevent.stop="isDragActive = false"
    @dragleave.prevent.stop="isDragActive = false"
    @drop.prevent.stop="onDrop"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const emit = defineEmits<{ (e: 'file-change', file: File): void }>();

const isDragActive = ref(false);

const onDrop = (e: DragEvent) => {
  isDragActive.value = false;
  const file = e.dataTransfer?.files[0];

  if (file) {
    emit('file-change', file);
  }
};
</script>

<style lang="scss" scoped>
.drop-zone {
  padding: 24px 16px;
  border: 2px dashed $color-gray-200;
  border-radius: 12px;

  &.active {
    border-color: $color-gray-400;

    & > * {
      pointer-events: none;
    }
  }
}
</style>
