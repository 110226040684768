<!-- Container element which adjusts itself to fit between the app's global header and footer  -->
<template>
  <FlexContainer vertical gap="16" class="sticky-column" :style="styles">
    <slot />
  </FlexContainer>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';

import FlexContainer from '@/components/FlexContainer.vue';
import { HEADER_HEIGHT, FOOTER_HEIGHT, MAIN_PADDING, FOOTER_ID } from '@/constants/layout';
import type { CSSStyles } from '@/types/utility';

const styles = ref<CSSStyles>({
  top: `${HEADER_HEIGHT}px`,
  padding: `${MAIN_PADDING}px 0`,
  margin: `-${MAIN_PADDING}px 0`,
});

const handleResize = () => {
  const footer = document.querySelector(`#${FOOTER_ID}`);
  const footerHeight = footer ? FOOTER_HEIGHT : 0;
  const mainPaddingHeight = MAIN_PADDING * 2;
  const surroundingHeight = HEADER_HEIGHT + mainPaddingHeight + footerHeight;
  styles.value.maxHeight = `${window.innerHeight - surroundingHeight}px`;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
.sticky-column {
  position: sticky;
  overflow-y: auto;
  overscroll-behavior-y: contain;
}
</style>
