<!-- Page for displaying and filtering all intake content -->
<template>
  <FlexContainer center justified class="title">
    <h2 class="x-large-headline">Dashboard</h2>
    <CButton large @click="onCreate">Create ad</CButton>
  </FlexContainer>
  <DashboardFilters
    :list-mode="listMode"
    @mode-change="(isList) => (listMode = isList)"
    @filter-change="onFilterChange"
  />
  <NoAdsCard v-if="!ads?.length" :loading="loading" :filtered="filtered" @click="onCreate" />
  <div v-else-if="!listMode" class="grid-view">
    <AdCard v-for="ad in ads" :key="ad.id" :ad="ad" />
  </div>
  <AdList v-else :ads="ads" />
  <Pagination v-if="ads?.length" :list="ads" @previous="previousPage" @next="nextPage" />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import AdCard from '@/components/AdCard.vue';
import AdList from '@/components/AdList.vue';
import DashboardFilters from '@/components/DashboardFilters.vue';
import FlexContainer from '@/components/FlexContainer.vue';
import NoAdsCard from '@/components/NoAdsCard.vue';
import Pagination from '@/components/Pagination.vue';
import { useResource } from '@/composables/useResource';
import type { List } from '@/helpers/resource';
import type { IntakeContent } from '@/types/content';

const loading = ref(true);
const ads = ref<List<IntakeContent>>();
const filtered = ref(false);
const listMode = ref(false);

const router = useRouter();
const { list, create } = useResource('intakecontent');

// Sorts most recently updated first.
const defaultFilters = { ordering: '-last_updated_at_utc' };

const onCreate = async () => {
  const { id } = await create({ template: 253 });
  router.push(`/ad/${id}`);
};

const onFilterChange = async (filters: Record<string, string>) => {
  filtered.value = Object.keys(filters).length > 0;
  ads.value = await list({ ...defaultFilters, ...filters });
};

const previousPage = async () => (ads.value = await ads.value?.previous?.());
const nextPage = async () => (ads.value = await ads.value?.next?.());

onMounted(async () => {
  ads.value = await list(defaultFilters);
  loading.value = false;
});
</script>

<style lang="scss" scoped>
.title {
  margin-top: 12px;
}

.grid-view {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
