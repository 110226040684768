// Imports and registers Vue 3 compatible components used by this app.

import CBadge from '@propelinc/citrus-ui/src/components/CShoelaceBadge.vue';
import CButton from '@propelinc/citrus-ui/src/components/CShoelaceButton.vue';
import CCard from '@propelinc/citrus-ui/src/components/CShoelaceCard.vue';
import CIconButton from '@propelinc/citrus-ui/src/components/CShoelaceIconButton.vue';
import CStatusDot from '@propelinc/citrus-ui/src/components/CShoelaceStatusDot.vue';
import CTextField from '@propelinc/citrus-ui/src/components/CShoelaceTextField.vue';
import CTextLink from '@propelinc/citrus-ui/src/components/CShoelaceTextLink.vue';
import type { Plugin } from 'vue';

// Enable type checking on globally registered components
declare module 'vue' {
  export interface GlobalComponents {
    CBadge: typeof CBadge;
    CButton: typeof CButton;
    CCard: typeof CCard;
    CIconButton: typeof CIconButton;
    CStatusDot: typeof CStatusDot;
    CTextField: typeof CTextField;
    CTextLink: typeof CTextLink;
  }
}

const plugin: Plugin = {
  install(app) {
    app
      .component('CBadge', CBadge)
      .component('CButton', CButton)
      .component('CCard', CCard)
      .component('CIconButton', CIconButton)
      .component('CStatusDot', CStatusDot)
      .component('CTextField', CTextField)
      .component('CTextLink', CTextLink);
  },
};

export default plugin;
