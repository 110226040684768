<!-- Card for displaying intake content details on the intake dashboard's card view -->
<template>
  <FlexContainer vertical gap="12" class="ad-card">
    <StatusBadge :status="ad.status" />
    <h3 :class="{ 'unread-title': isNew(ad.last_updated_at_utc, ad.last_viewed_at_utc) }">
      {{ ad.name || 'Untitled' }}
    </h3>
    <span class="last-updated">
      Last updated: <time :datetime="ad.last_updated_at_utc">{{ timestamp }}</time>
    </span>
    <img :src="ad.preview_image" width="280" alt="" class="preview" />
    <CButton secondary :to="`/ad/${ad.id}`">See ad details</CButton>
  </FlexContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import FlexContainer from '@/components/FlexContainer.vue';
import StatusBadge from '@/components/StatusBadge.vue';
import { isNew } from '@/helpers/activity';
import type { IntakeContent } from '@/types/content';

const props = defineProps<{ ad: IntakeContent }>();

const timestamp = computed(() => {
  const date = new Date(`${props.ad.last_updated_at_utc}Z`);
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
});
</script>

<style lang="scss" scoped>
.ad-card {
  padding: 24px;
  border: $border;
  border-radius: $border-radius-next;
  background-color: $color-white;
  color: $color-black;
  text-decoration: none;
}

.unread-title {
  position: relative;
  font-weight: $font-weight-emphasized;

  &::before {
    position: absolute;
    top: 5px;
    left: -14px;
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 5px;
    background-color: $color-red-500;
    content: '';
  }
}

.last-updated {
  @include caption;

  color: $color-text-secondary;
}

.preview {
  align-self: center;
  padding: 12px;
  margin-top: auto;
}
</style>
