<!-- Containing form for all intake fields -->
<template>
  <FlexContainer tag="form" vertical gap="16" @submit.prevent="$emit('submit')">
    <AdCreative
      :ad="ad"
      :fields-config="splitConfigs.creativeFields"
      :unread-count="unreadCount"
      @unread-click="$emit('unread-click')"
      @meta-change="(name, value) => $emit('meta-change', name, value)"
      @field-change="(name, value) => $emit('field-change', name, value)"
    />
    <AdVisuals
      :ad="ad"
      :fields-config="splitConfigs.imageFields"
      @field-change="(name, value) => $emit('field-change', name, value)"
    />
    <SectionCard>
      <template #title> Leave a comment </template>
      <div class="sub-title">
        Tell us if you have questions, updates, or anything else you would like us to know.
      </div>
      <CTextField
        id="comment"
        label="Comment"
        placeholder="Comment"
        hide-details
        :value="comment"
        @input="(value) => $emit('comment-change', value)"
      />
    </SectionCard>
    <Footer>
      <CButton large secondary to="/">Close</CButton>
      <CButton large type="submit">Submit for review</CButton>
    </Footer>
  </FlexContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import AdCreative from '@/components/AdCreative.vue';
import AdVisuals from '@/components/AdVisuals.vue';
import FlexContainer from '@/components/FlexContainer.vue';
import Footer from '@/components/Footer.vue';
import SectionCard from '@/components/SectionCard.vue';
import type { IntakeContent } from '@/types/content';
import type { FieldConfig, ImageField } from '@/types/fields';

interface SplitConfigs {
  creativeFields: FieldConfig[];
  imageFields: ImageField[];
}

const props = defineProps<{
  ad: IntakeContent;
  fieldsConfig: FieldConfig[];
  comment: string;
  unreadCount: number;
}>();
defineEmits<{
  (e: 'unread-click'): void;
  (e: 'meta-change', name: keyof IntakeContent, value: IntakeContent[typeof name]): void;
  (e: 'field-change', name: string, value: string): void;
  (e: 'comment-change', value: string): void;
  (e: 'submit'): void;
}>();

const splitConfigs = computed(() =>
  props.fieldsConfig.reduce<SplitConfigs>(
    (acc, fieldConfig) => {
      const target = fieldConfig.type === 'image' ? acc.imageFields : acc.creativeFields;
      target.push(fieldConfig);
      return acc;
    },
    { creativeFields: [], imageFields: [] }
  )
);
</script>

<style lang="scss" scoped>
.sub-title {
  margin: 4px 0 16px;
}
</style>
