<template>
  <FlexContainer tag="header" center justified>
    <FlexContainer center>
      <RouterLink class="logo-link" to="/">
        <img height="40" src="/img/logo.svg" alt="Propel Ads Manager Home" />
      </RouterLink>
      <div class="divider" />
      <h1 class="subheadline">Ads Manager</h1>
    </FlexContainer>
    <NavProfile v-if="profile" :user="profile" />
  </FlexContainer>
  <main>
    <RouterView />
  </main>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

import FlexContainer from '@/components/FlexContainer.vue';
import NavProfile from '@/components/NavProfile.vue';
import { useResource } from '@/composables/useResource';
import type { User } from '@/types/user';

const { get } = useResource('profile');
const profile = ref<User>();

onMounted(async () => {
  profile.value = await get();
});
</script>

<style lang="scss" scoped>
// NOTE: Keep these up to date with `@/constants/layout.ts`
$header-height: 80px;
$main-padding: 16px;

header {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  height: $header-height;
  box-sizing: border-box;
  padding: 0 24px;
  border-bottom: $border;
  background-color: $color-white;
}

.logo-link {
  display: contents;
}

.divider {
  width: 1px;
  height: 34px;
  margin: 0 16px;
  background: $color-gray-400;
}

main {
  max-width: 1200px;
  padding: $main-padding;
  margin: 0 auto;
  margin-top: $header-height;
}
</style>
