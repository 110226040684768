<!-- Page containing ad intake form, preview, and activity. Handles content updates -->
<template>
  <FlexContainer v-if="ad && fieldsConfig" gap="16" class="ad-view">
    <AdForm
      class="ad-form"
      :ad="ad"
      :fields-config="fieldsConfig"
      :unread-count="displayUpdates.unreadCount"
      :comment="comment"
      @unread-click="onUnreadClick"
      @meta-change="onMetaChange"
      @field-change="onFieldChange"
      @comment-change="(value) => (comment = value)"
      @submit="onSubmit"
    />
    <StickyColumn class="ad-context">
      <Preview ref="preview" :template-id="ad.template" :fields="ad.block_values" />
      <Activity
        ref="activity"
        :updates="displayUpdates.updates"
        :fields-config="fieldsConfig"
        :last-viewed-at="ad.last_viewed_at_utc"
      />
    </StickyColumn>
  </FlexContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, useTemplateRef } from 'vue';

import Activity from '@/components/Activity.vue';
import AdForm from '@/components/AdForm.vue';
import FlexContainer from '@/components/FlexContainer.vue';
import Preview from '@/components/Preview.vue';
import StickyColumn from '@/components/StickyColumn.vue';
import { useResource } from '@/composables/useResource';
import { processActivity } from '@/helpers/activity';
import type { IntakeContent, IntakeContentActivity } from '@/types/content';
import type { FieldConfig } from '@/types/fields';

const props = defineProps<{ adId: string }>();

const preview = useTemplateRef('preview');
const activity = useTemplateRef('activity');
const { get } = useResource('intakecontent');
const { list: getActivity, create: saveAd } = useResource('intakecontentactivity');
const { get: getTemplateConfig } = useResource('templateblockconfig');

const ad = ref<IntakeContent>();
const updates = ref<IntakeContentActivity[]>([]);
const fieldsConfig = ref<FieldConfig[]>();
const comment = ref('');

let savedAd: IntakeContent;

const displayUpdates = computed(() => processActivity(updates.value, ad.value?.last_viewed_at_utc));

const onSubmit = async () => {
  const adToSave: IntakeContent = JSON.parse(JSON.stringify(ad.value));
  if (adToSave) {
    // Generate preview image
    const previewImage = await preview.value?.renderImage();
    if (previewImage) {
      adToSave.preview_image = previewImage;
    }

    // Compare current ad values with last saved values
    const metaChanges: IntakeContentActivity['meta_changes'] = {};
    (['name', 'link', 'preview_image'] as const).forEach((key) => {
      if (adToSave[key] !== savedAd[key]) {
        metaChanges[key] = { old: savedAd[key], new: adToSave[key] || '' };
      }
    });
    const fieldChanges: IntakeContentActivity['block_changes'] = {};
    for (const key in adToSave.block_values) {
      const oldValue = savedAd.block_values[key];
      const newValue = adToSave.block_values[key];
      if (oldValue !== newValue) {
        fieldChanges[key] = { old: oldValue, new: newValue };
      }
    }
    const update = await saveAd({
      content: parseInt(props.adId),
      block_changes: fieldChanges,
      meta_changes: metaChanges,
      comment: comment.value,
    });
    // Store this version of the ad for comparing future updates against.
    savedAd = adToSave;
    // Clear comment field and update UI to reflect save.
    comment.value = '';
    updates.value.push(update);
    if (ad.value) {
      ad.value.last_updated_at_utc = update.occurred_at_utc;
      ad.value.last_viewed_at_utc = update.occurred_at_utc;
    }
  }
};

const onUnreadClick = () => activity.value?.scrollIntoView();
const onMetaChange = <K extends keyof IntakeContent>(name: K, value: IntakeContent[K]) =>
  ad.value && (ad.value[name] = value);
const onFieldChange = (name: string, value: string) =>
  ad.value && (ad.value.block_values[name] = value);

onMounted(async () => {
  getActivity({ content: props.adId }).then((response) => (updates.value = response));
  ad.value = await get(props.adId);
  savedAd = JSON.parse(JSON.stringify(ad.value));
  fieldsConfig.value = (await getTemplateConfig(ad.value.template)).blocks || [];
});
</script>

<style lang="scss" scoped>
.ad-view {
  max-width: 1220px;
  margin: 0 auto;
}

.ad-form {
  min-width: 390px;
  height: fit-content;
  flex: 3;
}

.ad-context {
  min-width: 390px;
  flex: 2;
}
</style>
