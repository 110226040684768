import colors from '@propelinc/citrus-ui/src/colors/colors';

const { BLUE_200, PURPLE_200, GREEN_200, GOLD_200, RED_200 } = colors;

const PROFILE_COLORS = [BLUE_200, PURPLE_200, GREEN_200, GOLD_200, RED_200];

/** Gets the background color for a user's profile based on their email address. */
export const colorForProfile = (email: string) => {
  // Get a "random-enough" number by adding up the unicode value of every character in the string.
  const stringValue = email.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return PROFILE_COLORS[stringValue % PROFILE_COLORS.length];
};
