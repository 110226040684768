import type { DisplayUpdate, IntakeContentActivity } from '@/types/content';

/**
 * Returns whether a change has occurred since the last view. If no view exists, defaults to false.
 */
export const isNew = (changedAt: string, viewedAt: string | undefined) =>
  viewedAt ? new Date(changedAt).getTime() > new Date(viewedAt).getTime() : false;

/**
 * Given a list of IntakeContentActivity, returns a list of DisplayUpdates,
 * which contain the specific information needed by the UI in a consistent format.
 */
export const processActivity = (activity: IntakeContentActivity[], lastViewedAt?: string) =>
  activity
    .sort(
      ({ occurred_at_utc: a }, { occurred_at_utc: b }) =>
        new Date(b).getTime() - new Date(a).getTime()
    )
    // In addition to formatting, this reducer splits any update with more than one change type
    // into multiple items, as we want to display them separately in the UI.
    .reduce<{ updates: DisplayUpdate[]; unreadCount: number }>(
      (acc, update) => {
        const unread = isNew(update.occurred_at_utc, lastViewedAt);

        const baseDisplayUpdate = {
          user: update.user,
          occurred_at_utc: update.occurred_at_utc,
          unread,
        };

        if (update.comment) {
          acc.updates.push({
            ...baseDisplayUpdate,
            id: `${update.id}_comment`,
            changes: { _comment: { new: update.comment } },
          });
          if (unread) {
            acc.unreadCount++;
          }
        }

        if (update.meta_changes.status) {
          acc.updates.push({
            ...baseDisplayUpdate,
            id: `${update.id}_status`,
            changes: { _status: update.meta_changes.status },
          });
          if (unread) {
            acc.unreadCount++;
          }
        }

        if (Object.keys(update.block_changes).length) {
          acc.updates.push({
            ...baseDisplayUpdate,
            id: `${update.id}_block_changes`,
            changes: update.block_changes,
          });
          if (unread) {
            acc.unreadCount++;
          }
        }

        return acc;
      },
      { updates: [], unreadCount: 0 }
    );
