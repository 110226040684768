/** Returns a descriptive error message if 'actual' is not larger than 'min' */
const checkDimension = (actual: number, min: number, label: string) =>
  actual < min
    ? `Your image is ${actual}px ${label}. Choose an image that is at least ${min}px ${label}.`
    : '';

/**
 * Given an image file and minimum dimensions, returns a promise which either resolves if the image
 * exceeds the dimensions, or rejects with a user-friendly error message if it does not.
 */
export const validateImageSize = (file: File, minWidth = 0, minHeight = 0) =>
  new Promise<void>((resolve, reject) => {
    if (minWidth || minHeight) {
      const image = new Image();
      image.addEventListener('load', () => {
        const { width, height } = image;

        const error =
          checkDimension(width, minWidth, 'wide') || checkDimension(height, minHeight, 'tall');

        URL.revokeObjectURL(image.src);

        if (error) {
          reject(error);
        } else {
          resolve();
        }
      });
      image.src = URL.createObjectURL(file);
    } else {
      resolve();
    }
  });
