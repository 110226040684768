/** Returns a copy of the supplied field list, sorted by their 'ordering' property, ascending */
export const orderFields = <T extends { ordering?: number }>(fields: T[]) =>
  fields.toSorted(({ ordering: a = Infinity }, { ordering: b = Infinity }) => a - b);

type Options = { label: string; value: string }[];

/**
 * Given a list of options (as used by a <select> element) and an option 'value', returns the
 * corresponding 'label'. Falls back to the provided 'value'.
 * */
export const valueToLabel = (options: Options, value: string) => {
  const match = options.find((option) => option.value === value);
  return match?.label || value;
};

/**
 * Given a list of options (as used by a <select> element) and an option 'label', returns the
 * corresponding 'value'. Falls back to the provided 'label'.
 * */
export const labelToValue = (options: Options, label: string) => {
  const match = options.find((option) => option.label === label);
  return match?.value || label;
};
