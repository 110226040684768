<!--
Takes HTML and CSS strings as props and renders them into the DOM.
Any globally registered Vue components encountered in the html will be rendered appropriately.
All selectors in the CSS will be scoped such that they only affect the provided HTML.
-->
<script lang="ts">
import { h, onMounted, useId, useTemplateRef } from 'vue';

const isStyleRule = (rule: CSSRule): rule is CSSStyleRule =>
  rule.constructor.name === 'CSSStyleRule';

const scopeCss = (sheet: CSSStyleSheet, scopeAttribute: string) => {
  Array.from(sheet.cssRules).forEach((rule, i) => {
    if (isStyleRule(rule)) {
      // Replace each selector (separated by commas) with a scoped version.
      const scopedSelectors = rule.selectorText.replace(/[^,\s][^,]*/g, `[${scopeAttribute}] $&`);
      const scopedRule = rule.cssText.replace(rule.selectorText, scopedSelectors);

      sheet.deleteRule(i);
      sheet.insertRule(scopedRule, i);
    }
  });
};

export default {
  props: {
    html: { type: String, default: '' },
    css: { type: String, default: '' },
  },
  setup(props) {
    const scopeAttribute = `data-cms-css-for="${useId()}"`;

    return () =>
      h({
        name: 'DynamicContent',
        template: `
          <div ${scopeAttribute}>
            ${props.html}
            <component is="style" ref="style">${props.css}</component>
          </div>
        `,
        setup() {
          const styleElement = useTemplateRef<HTMLStyleElement>('style');

          onMounted(() => {
            if (styleElement.value?.sheet) {
              scopeCss(styleElement.value.sheet, scopeAttribute);
            }
          });
        },
      });
  },
};
</script>
