<!-- "Chip" which displays a user's avatar if available, otherwise their initials  -->
<template>
  <FlexContainer class="profile-chip subheadline">
    <img v-if="user.picture" :src="user.picture" alt="" />
    <FlexContainer v-else center> {{ initials }} </FlexContainer>
  </FlexContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import FlexContainer from '@/components/FlexContainer.vue';
import { colorForProfile } from '@/helpers/color';
import type { User } from '@/types/user';

const props = defineProps<{ user: User }>();

const initials = computed(() => {
  const { first_name: first = '', last_name: last = '' } = props.user;
  return `${first[0]}${last[0]}`.toUpperCase() || '?';
});
const background = computed(() => colorForProfile(props.user.email));
</script>

<style lang="scss" scoped>
.profile-chip {
  overflow: hidden;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: v-bind(background);
  font-family: $font-family-serif;
  font-size: 20px;

  & > * {
    width: 100%;
  }
}
</style>
