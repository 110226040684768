<!-- List item with intake content details, for display within the intake dashboard's list view -->
<template>
  <tr
    class="ad-list-item"
    :class="{ unread: isNew(ad.last_updated_at_utc, ad.last_viewed_at_utc) }"
    @click="$router.push(`/ad/${ad.id}`)"
  >
    <!-- TODO: Checkbox -->
    <td><StatusBadge :status="ad.status" /></td>
    <td class="name">{{ ad.name || 'Untitled' }}</td>
    <td>{{ ad.customer_name }}</td>
    <td>
      <time :datetime="ad.last_updated_at_utc">{{ timestamp }}</time>
    </td>
    <td>{{ ad.last_updated_by.first_name }} {{ ad.last_updated_by.last_name[0] }}</td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import StatusBadge from '@/components/StatusBadge.vue';
import { isNew } from '@/helpers/activity';
import type { IntakeContent } from '@/types/content';

const props = defineProps<{ ad: IntakeContent }>();

const timestamp = computed(() => {
  const date = new Date(`${props.ad.last_updated_at_utc}Z`);
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
});
</script>

<style lang="scss" scoped>
.ad-list-item {
  background-color: $color-white;
  cursor: pointer;

  &:hover {
    background-color: $color-blue-100;
  }
}

td {
  padding: 20px 16px;
  border-bottom: $border;
}

.ad-list-item:last-of-type > td {
  border-bottom: none;
}

.unread {
  font-weight: $font-weight-emphasized;

  & .name {
    position: relative;

    &::before {
      position: absolute;
      top: 27px;
      left: 0;
      display: block;
      width: 9px;
      height: 9px;
      border-radius: 5px;
      background-color: $color-red-500;
      content: '';
    }
  }
}
</style>
