<!-- Temporary version of Citrus Modal until it is built. -->
<template>
  <sl-dialog ref="modal" :open="value" class="c-modal">
    <div slot="label" class="title large-headline">
      <slot name="title" />
    </div>
    <div slot="header-actions">
      <slot name="title-adornments" />
    </div>
    <slot />
  </sl-dialog>
</template>

<script setup lang="ts">
import SlDialog from '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import { useTemplateRef } from 'vue';

defineProps<{ value?: boolean }>();

const modal = useTemplateRef<SlDialog>('modal');

const show = () => modal.value?.show();
const hide = () => modal.value?.hide();

defineExpose({ show, hide });
</script>

<style lang="scss" scoped>
.c-modal {
  --width: 75vw;
}

.c-modal::part(title) {
  padding-bottom: 0;
}

.c-modal::part(header-actions) {
  align-items: center;
  gap: var(--header-spacing);
}
</style>
