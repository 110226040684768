<!-- Individual intake form field, handling "image" type fields. Handles validation and upload -->
<template>
  <FlexContainer vertical gap="8">
    <input :id="name" type="file" :accept="ALLOWED_TYPES.join()" hidden @change="onFileSelect" />
    <label :for="name">
      <b class="label-text">{{ label }}</b>
      <DropZone @file-change="handleFile">
        <ImagePreview
          v-if="value"
          :url="value"
          :name="value.split('/').pop() || ''"
          @clear="$emit('change', '')"
        />
        <div v-else>
          <FontAwesomeIcon :icon="faImage" class="image-icon" />
          <CTextLink>Click to upload</CTextLink> your image or
          <ImageLibrary @select="(url) => $emit('change', url)">choose from library</ImageLibrary>
        </div>
      </DropZone>
    </label>
    <div class="image-specs">
      Format: JPEG or PNG
      <span v-if="minWidth || minHeight" class="image-size">
        Minimum size:
        <span v-if="minWidth && minHeight">{{ minWidth }}x{{ minHeight }} pixels</span>
        <span v-else-if="minWidth">{{ minWidth }} pixels wide</span>
        <span v-else> {{ minHeight }} pixels tall</span>
      </span>
    </div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </FlexContainer>
</template>

<script setup lang="ts">
import { faImage } from '@fortawesome/pro-regular-svg-icons';
import { ref } from 'vue';
import { FontAwesomeIcon } from 'vue3-fontawesome';

import DropZone from '@/components/DropZone.vue';
import FlexContainer from '@/components/FlexContainer.vue';
import ImageLibrary from '@/components/ImageLibrary.vue';
import ImagePreview from '@/components/ImagePreview.vue';
import { useResource } from '@/composables/useResource';
import { validateImageSize } from '@/helpers/image';
import type { ImageField } from '@/types/fields';

const ALLOWED_TYPES = ['image/jpeg', 'image/png'];
const DEFAULT_ERROR = 'Unable to process your image. Please try again.';

const props = defineProps<ImageField & { value?: string }>();
const emit = defineEmits<{ (e: 'change', url: string): void }>();

const { create: upload } = useResource('customerimage');

const errorMessage = ref('');

const handleFile = async (file: File | undefined) => {
  errorMessage.value = '';

  if (!file || !ALLOWED_TYPES.includes(file.type)) {
    errorMessage.value = 'Invalid file type';
    return;
  }

  try {
    await validateImageSize(file, props.minWidth, props.minHeight);
  } catch (err) {
    errorMessage.value = typeof err === 'string' ? err : DEFAULT_ERROR;
    return;
  }

  // TODO: Open cropping tool

  const formData = new FormData();
  formData.append('file', file);

  // TODO: The cropping tool should also provide a name field, which will populate "label"
  // formData.append('data', JSON.stringify({ name: 'Test image' }));

  const image = await upload(formData);

  emit('change', image.url);
};

const onFileSelect = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const file = target.files?.[0];
  handleFile(file);
};
</script>

<style lang="scss" scoped>
.label-text {
  display: block;
  margin-bottom: 8px;
}

.image-icon {
  margin-right: 12px;
}

.image-specs {
  @include caption;

  color: $color-text-placeholder;
}

.image-size {
  margin-left: 12px;
}

.error-message {
  @include caption;

  color: $color-alert-red;
}
</style>
