<!-- Pagination controls for the intake dashboard -->
<template>
  <FlexContainer center class="pagination">
    {{ limits.start }}-{{ limits.end }} of {{ list.total }}
    <CIconButton
      tertiary
      :icon="faChevronLeft"
      aria-label="Previous page"
      :disabled="!list.previous"
      class="previous-button"
      @click="$emit('previous')"
    />
    <CIconButton
      tertiary
      :icon="faChevronRight"
      aria-label="Next page"
      :disabled="!list.next"
      class="next-button"
      @click="$emit('next')"
    />
  </FlexContainer>
</template>

<script setup lang="ts">
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { computed } from 'vue';

import FlexContainer from '@/components/FlexContainer.vue';
import type { List } from '@/helpers/resource';

const props = defineProps<{ list: List<unknown> }>();
defineEmits<{ (e: 'previous'): void; (e: 'next'): void }>();

const limits = computed(() => {
  const { page, pageSize, length } = props.list;
  const start = (page - 1) * pageSize + 1;
  return { start, end: start + length - 1 };
});
</script>

<style lang="scss" scoped>
.pagination {
  @include caption;

  padding: 12px 0;
  margin-right: auto;
  color: $color-text-secondary;
}

.previous-button {
  margin-left: 8px;
}

.next-button {
  margin-right: auto;
}
</style>
