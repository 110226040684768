<!-- Temporary version of Citrus Popup until it is built. -->
<template>
  <sl-popup ref="popup" :active="value">
    <span slot="anchor">
      <slot />
    </span>
    <slot name="content" />
  </sl-popup>
</template>

<script setup lang="ts">
import SlPopup from '@shoelace-style/shoelace/dist/components/popup/popup.js';
import { onMounted, onUnmounted, useTemplateRef, watchEffect } from 'vue';

const props = defineProps<{ value: boolean }>();
const emit = defineEmits<{ (e: 'mouseleave'): void; (e: 'outside-click'): void }>();

const popup = useTemplateRef('popup');

const handleMouseLeave = () => {
  if (props.value) {
    emit('mouseleave');
  }
};

const handleOutsideClick = ({ target }: MouseEvent) => {
  if (target instanceof HTMLElement && !popup.value?.contains(target)) {
    emit('outside-click');
  }
};

watchEffect(async () => {
  if (props.value) {
    document.addEventListener('click', handleOutsideClick);
  } else {
    document.removeEventListener('click', handleOutsideClick);
  }
});

onMounted(() => {
  popup.value?.addEventListener('mouseleave', handleMouseLeave);
});

onUnmounted(() => {
  document.removeEventListener('click', handleOutsideClick);
  popup.value?.removeEventListener('mouseleave', handleMouseLeave);
});
</script>
