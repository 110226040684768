<!-- Profile preview and navigation dropdown for use in the global header -->
<template>
  <CPopup placement="bottom-end" :value="isOpen" @outside-click="isOpen = false">
    <FlexContainer center gap="12" class="nav-profile" @click="() => (isOpen = !isOpen)">
      <ProfileChip :user="user" />
      <b> {{ user.first_name }} {{ user.last_name }} </b>
      <FontAwesomeIcon :icon="faChevronDown" />
    </FlexContainer>
    <template #content>
      <CMenu @click="isOpen = false">
        <CMenuItem>
          <template #prepend-inner>
            <FlexContainer center class="menu-icon">
              <FontAwesomeIcon :icon="faUser" />
            </FlexContainer>
          </template>
          Account
        </CMenuItem>
        <RouterLink to="/admin" class="menu-link">
          <CMenuItem>
            <template #prepend-inner>
              <FlexContainer center class="menu-icon">
                <FontAwesomeIcon :icon="faGear" />
              </FlexContainer>
            </template>
            Admin
          </CMenuItem>
        </RouterLink>
        <a href="/logout" class="menu-link">
          <CMenuItem>
            <template #prepend-inner>
              <FlexContainer center class="menu-icon">
                <FontAwesomeIcon :icon="faArrowRightFromBracket" />
              </FlexContainer>
            </template>
            Log out
          </CMenuItem>
        </a>
      </CMenu>
    </template>
  </CPopup>
</template>

<script setup lang="ts">
import {
  faChevronDown,
  faUser,
  faArrowRightFromBracket,
  faGear,
} from '@fortawesome/pro-regular-svg-icons';
import { ref } from 'vue';
import { FontAwesomeIcon } from 'vue3-fontawesome';

import CMenu from '@/components/CMenu.vue';
import CMenuItem from '@/components/CMenuItem.vue';
import CPopup from '@/components/CPopup.vue';
import FlexContainer from '@/components/FlexContainer.vue';
import ProfileChip from '@/components/ProfileChip.vue';
import type { User } from '@/types/user';

defineProps<{ user: User }>();

const isOpen = ref(false);
</script>

<style lang="scss" scoped>
.nav-profile {
  padding: 12px 26px;
  cursor: pointer;
}

.menu-link {
  text-decoration: none;
}

.menu-icon {
  width: 40px;
  height: 40px;
  border-radius: $border-radius;
  background-color: $color-gray-100;
}
</style>
