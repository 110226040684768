<!-- Page for admin tools -->
<template>
  <SectionCard>
    <template #title>Template configuration</template>
    <template #title-adornment>
      <RouterLink to="/admin/template-config">
        <CButton> Create </CButton>
      </RouterLink>
    </template>
    <ul v-if="configs.length">
      <li v-for="{ template, name } in configs" :key="template">
        <RouterLink :to="`/admin/template-config/${template}`">{{ name }}</RouterLink>
      </li>
    </ul>
    <p v-else>No configs</p>
  </SectionCard>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

import SectionCard from '@/components/SectionCard.vue';
import { useResource } from '@/composables/useResource';
import type { TemplateBlockConfig } from '@/types/fields';

const configs = ref<TemplateBlockConfig[]>([]);

const { list } = useResource('templateblockconfig');

onMounted(async () => {
  configs.value = (await list()) || [];
});
</script>
