<!-- Page for configuring how all a template's blocks should appear in the intake form -->
<template>
  <SectionCard>
    <template #title>Template Configuration</template>
    <template #title-adornment>{{ templateName }}</template>
    <form @submit.prevent="onSubmit">
      <FlexContainer vertical gap="16">
        <FieldSettings
          v-for="field in sortedFields"
          :key="field.name"
          :field="field"
          @change="(update) => Object.assign(field, update)"
        />
      </FlexContainer>
      <Footer>
        <RouterLink to="/admin"><CButton secondary>Close</CButton></RouterLink>
        <FlexContainer gap="8">
          <CButton secondary :disabled="inFlight.patch" @click="onDelete">Delete</CButton>
          <CButton type="submit" :disabled="inFlight.patch">Update</CButton>
        </FlexContainer>
      </Footer>
    </form>
  </SectionCard>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';

import FieldSettings from '@/components/FieldSettings.vue';
import FlexContainer from '@/components/FlexContainer.vue';
import Footer from '@/components/Footer.vue';
import SectionCard from '@/components/SectionCard.vue';
import { useResource } from '@/composables/useResource';
import type { FieldConfig } from '@/types/fields';

const props = defineProps<{ templateId: string }>();

const router = useRouter();

const fieldConfig = ref<FieldConfig[]>([]);
const templateName = ref('');

const { get, update, destroy, inFlight } = useResource('templateblockconfig');

const sortedFields = computed(() =>
  [...fieldConfig.value].sort(({ ordering: a = Infinity }, { ordering: b = Infinity }) => a - b)
);

onMounted(async () => {
  const config = await get(props.templateId);
  fieldConfig.value = config.blocks;
  templateName.value = config.name;
});

const onDelete = async () => {
  await destroy(props.templateId);
  router.push('.');
};

const onSubmit = () => {
  if (!inFlight.value.patch) {
    update(props.templateId, { blocks: fieldConfig.value });
  }
};
</script>

<style lang="scss" scoped>
form {
  margin-top: 16px;
}
</style>
