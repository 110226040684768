<!-- Page for creating a new template configuration record -->
<template>
  <SectionCard sticky-footer>
    <template #title>Template Configuration</template>
    <template #title-adornment>New</template>
    <form @submit.prevent="handleSubmit">
      <CTextField
        id="templateId"
        :value="templateId"
        label="Template ID"
        :disabled="inFlight.post"
        @input="(val) => (templateId = val)"
      />
      <Footer>
        <RouterLink to="/admin"><CButton secondary>Close</CButton></RouterLink>
        <FlexContainer gap="8">
          <CButton type="submit">Create</CButton>
        </FlexContainer>
      </Footer>
    </form>
  </SectionCard>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import FlexContainer from '@/components/FlexContainer.vue';
import Footer from '@/components/Footer.vue';
import SectionCard from '@/components/SectionCard.vue';
import { useResource } from '@/composables/useResource';

const router = useRouter();
const { inFlight, create } = useResource('templateblockconfig');

const templateId = ref('');

const handleSubmit = async () => {
  if (!inFlight.value.post) {
    await create({ template: parseInt(templateId.value) });
    router.push(`/admin/template-config/${templateId.value}`);
  }
};
</script>

<style lang="scss" scoped>
form {
  margin-top: 16px;
}
</style>
