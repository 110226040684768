<!-- Individual content update details for use within the intake form's "Activity" panel -->
<template>
  <FlexContainer vertical gap="8" class="update">
    <CStatusDot v-if="update.unread" class="unread-indicator" aria-label="Unread" />
    <FlexContainer gap="8" class="metadata">
      <ProfileChip :user="update.user" />
      <FlexContainer vertical>
        <span>
          <b>{{ `${update.user?.first_name} ${update.user?.last_name} ` }}</b>
          <span v-if="update.changes._status?.new === 'in_progress'">created this ad</span>
          <span v-else-if="update.changes._status?.new === 'approved'">approved this ad</span>
          <span v-else-if="update.changes._comment">left a <b>comment</b></span>
          <span v-else-if="Object.keys(update.changes).length > 1">made multiple updates</span>
          <span v-else>made an update</span>
        </span>
        <time class="caption" :datetime="update.occurred_at_utc">{{ timestamp }}</time>
      </FlexContainer>
    </FlexContainer>
    <div v-if="!update.changes._status">
      <div v-for="(change, field) in update.changes" :key="field">
        <b v-if="field !== '_comment'">{{ fieldNameMap[field] }}: </b>
        <span v-if="change.old">
          {{ formatChange(field, change.old) }}
          <FontAwesomeIcon :icon="faArrowRight" class="change-arrow" />
        </span>
        {{ formatChange(field, change.new) }}
      </div>
    </div>
  </FlexContainer>
</template>

<script setup lang="ts">
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { computed } from 'vue';
import { FontAwesomeIcon } from 'vue3-fontawesome';

import FlexContainer from '@/components/FlexContainer.vue';
import ProfileChip from '@/components/ProfileChip.vue';
import type { DisplayUpdate } from '@/types/content';

const props = defineProps<{
  update: DisplayUpdate;
  fieldNameMap: Record<string, string>;
}>();

const timestamp = computed(() => {
  const date = new Date(`${props.update.occurred_at_utc}Z`);
  const timeString = date.toLocaleTimeString('en-US', { timeStyle: 'short' });
  const dateString = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  return `${timeString} ${dateString}`;
});

const formatChange = (fieldName: string, string: string) => {
  if (fieldName === '_comment') {
    return string;
  }

  // Temporary
  return `“${string.startsWith('http') ? string.split('/').pop() : string}“`;
};
</script>

<style lang="scss" scoped>
.update {
  position: relative;
  margin: 10px 0;
}

.metadata {
  padding-right: 28px;
}

.unread-indicator {
  position: absolute;
  top: 6px;
  right: 10px;
}

.change-arrow {
  margin: 0 4px;
}
</style>
