<!-- Controls for filtering and changing the display of intake contents on the intake dashboard -->
<template>
  <FlexContainer justified center full-width>
    <CTextField
      id="search"
      placeholder="Search by ad name"
      aria-label="Search by ad name"
      hide-label
      hide-details
      class="search"
      :value="search"
      @input="onSearchInput"
    >
      <template #prepend-inner><FontAwesomeIcon :icon="faMagnifyingGlass" /></template>
    </CTextField>
    <div class="mode-toggle">
      <CIconButton
        :icon="faGrid"
        :tertiary="listMode"
        aria-label="View as cards"
        @click="$emit('mode-change', false)"
      />
      <CIconButton
        :icon="faList"
        :tertiary="!listMode"
        aria-label="View as a list"
        @click="$emit('mode-change', true)"
      />
    </div>
  </FlexContainer>
</template>

<script setup lang="ts">
import { faGrid, faList, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { onUnmounted, ref } from 'vue';
import { FontAwesomeIcon } from 'vue3-fontawesome';

import FlexContainer from '@/components/FlexContainer.vue';

defineProps<{ listMode: boolean }>();
const emit = defineEmits<{
  (e: 'mode-change', isList: boolean): void;
  (e: 'filter-change', filters: Record<string, string>): void;
}>();

const search = ref('');
let searchTimeout = 0;

const constructFilters = () => {
  const filters: Record<string, string> = {};

  if (search.value) {
    filters.name__ilike = `%${search.value}%`;
  }

  return filters;
};

const onSearchInput = (value: string) => {
  clearTimeout(searchTimeout);
  searchTimeout = setTimeout(() => {
    search.value = value;
    emit('filter-change', constructFilters());
  }, 200);
};

onUnmounted(() => {
  clearTimeout(searchTimeout);
});
</script>

<style lang="scss" scoped>
.search {
  width: 45%;
  margin: 16px 0 4px;
}

.mode-toggle {
  display: block;
  height: fit-content;
  padding: 3px;
  border: 1.5px solid $color-black;
  border-radius: $border-radius-next;

  & > :first-child {
    margin-right: 8px;
  }
}
</style>
