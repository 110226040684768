<!-- Modal which displays a grid of all previously uploaded images -->
<template>
  <CModal ref="modal" @click.prevent.stop>
    <template #title>
      Image library
      <div class="subtitle">Select or upload new assets</div>
    </template>
    <template #title-adornments>
      <CButton secondary @click="onUploadClick">
        <template #icon>
          <FontAwesomeIcon :icon="faCloudArrowUp" />
        </template>
        Upload
      </CButton>
    </template>
    <div class="image-grid">
      <button
        v-for="image in images"
        :key="image.name"
        class="image-button"
        @click="select(image.url)"
      >
        <img :src="image.url" :alt="image.name" width="100%" />
        {{ image.name }}
      </button>
    </div>
  </CModal>
  <CTextLink @click.prevent.stop="modal?.show()">
    <slot />
  </CTextLink>
</template>

<script setup lang="ts">
import { faCloudArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { onMounted, ref, useTemplateRef } from 'vue';
import { FontAwesomeIcon } from 'vue3-fontawesome';

import CModal from '@/components/CModal.vue';
import { useResource } from '@/composables/useResource';
import type { CustomerImage } from '@/types/content';

const emit = defineEmits<{ (e: 'select', url: string): void }>();

const modal = useTemplateRef('modal');
const { list } = useResource('customerimage');

const images = ref<CustomerImage[]>([]);

const select = (url: string) => {
  emit('select', url);
  modal.value?.hide();
};

const onUploadClick = () => {
  modal.value?.hide();
  modal.value?.$el.parentElement.click();
};

onMounted(async () => {
  images.value = await list();
});
</script>

<style lang="scss" scoped>
.subtitle {
  @include body;

  font-family: $font-family-base;
}

.image-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

.image-button {
  @include body;

  max-width: 195px;
  padding: 12px;
  border: none;
  background-color: $color-white;
  cursor: pointer;
  font-family: $font-family-base;
}
</style>
