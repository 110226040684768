import { ref } from 'vue';

import type {
  AdPreview,
  IntakeContent,
  IntakeContentActivity,
  CustomerImage,
} from '@/types/content';
import type { TemplateBlockConfig } from '@/types/fields';
import type { User } from '@/types/user';

type Method = 'get' | 'post' | 'patch' | 'delete';
type Id = string | number;
type Params = Record<string, string>;
interface ListMeta {
  count: number;
  page: number;
  next: string;
  previous: string;
}
export type List<T> = T[] & {
  next?: () => Promise<List<T>>;
  previous?: () => Promise<List<T>>;
  page: number;
  pageSize: number;
  total: number;
};
interface RequestOptions {
  id?: Id;
  body?: object;
  params?: Params;
}

export interface ResourceMap {
  intakecontent: IntakeContent;
  intakecontentactivity: IntakeContentActivity;
  preview: AdPreview;
  profile: User;
  templateblockconfig: TemplateBlockConfig;
  customerimage: CustomerImage;
  // Fallback
  [name: string]: unknown;
}

/**
 * Helper class for dealing with REST resources.
 * Instantiate it with the name of your resource, which will automatically type all responses,
 * as long as the resource name is included in the ResourceMap interface.
 */
export class RestResource<T extends keyof ResourceMap> {
  private resourceName: T;
  private abortController = new AbortController();

  /** Tracks whether any requests are in-flight for each request method */
  inFlight = ref<Partial<Record<Method, true>>>({});

  constructor(rootPath: T) {
    this.resourceName = rootPath;
  }

  private async request<R>(method: Method, { id, body, params }: RequestOptions): Promise<R> {
    if (this.inFlight.value[method]) {
      throw new Error(
        `Only one ${method} request can be in flight at a time for '${this.resourceName}'. ` +
          `Use inFlight.value.${method} to gate additional calls.`
      );
    }

    this.inFlight.value[method] = true;

    try {
      const idPart = id ? `/${id}` : '';
      const queryString = params ? `?${new URLSearchParams(params).toString()}` : '';
      const options: RequestInit = { method, signal: this.abortController.signal };

      if (body instanceof FormData) {
        options.body = body;
      } else if (body) {
        options.body = JSON.stringify(body);
        options.headers = { 'Content-Type': 'application/json' };
      }

      const response = await fetch(`/api/${this.resourceName}${idPart}${queryString}`, options);

      if (!response.ok) {
        throw response;
      }

      const json = await response.json();

      delete this.inFlight.value[method];
      return json;
    } catch (err) {
      delete this.inFlight.value[method];
      throw err;
    }
  }

  list = async (params?: Params) => {
    const limit = params?.limit || '20';
    const { objects: result, meta } = await this.request<{
      objects: List<ResourceMap[T]>;
      meta: ListMeta;
    }>('get', { params: { ...params, limit } });

    if (meta.next) {
      result.next = () => this.list({ ...params, limit, page: `${meta.page + 1}` });
    }
    if (meta.previous) {
      result.previous = () => this.list({ ...params, limit, page: `${meta.page - 1}` });
    }

    result.page = meta.page;
    result.total = meta.count;
    result.pageSize = parseInt(limit);

    return result;
  };

  get = async (id?: Id, params?: Params) => this.request<ResourceMap[T]>('get', { id, params });

  create = (body: Partial<ResourceMap[T]> | FormData) =>
    this.request<ResourceMap[T]>('post', { body });

  update = (id: Id, body: Partial<ResourceMap[T]>) =>
    this.request<ResourceMap[T]>('patch', { id, body });

  destroy = async (id: Id) => this.request<void>('delete', { id });

  _abort(reason: string) {
    if (Object.keys(this.inFlight.value).length) {
      this.abortController.abort(`${this.resourceName} request aborted because ${reason}`);
    }
  }
}
