<!-- Container for intake content updates -->
<template>
  <div ref="root" class="activity-root">
    <SectionCard>
      <template #title>Activity</template>
      <FlexContainer ref="root" vertical gap="12" class="activity">
        <div v-if="!updates.length" class="empty-message">No activity yet</div>
        <AdUpdate
          v-for="update in updates"
          :key="update.id"
          :update="update"
          :field-name-map="fieldNameMap"
        />
      </FlexContainer>
    </SectionCard>
    <div ref="scroll-spacer" />
  </div>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue';

import AdUpdate from '@/components/AdUpdate.vue';
import FlexContainer from '@/components/FlexContainer.vue';
import SectionCard from '@/components/SectionCard.vue';
import type { DisplayUpdate } from '@/types/content';
import type { FieldConfig } from '@/types/fields';

const props = defineProps<{
  updates: DisplayUpdate[];
  fieldsConfig: FieldConfig[];
}>();

const root = useTemplateRef<HTMLDivElement>('root');
const scrollSpacer = useTemplateRef('scroll-spacer');

const scrollIntoView = () => {
  const rootEl = root.value;
  if (!rootEl || !rootEl.parentElement) {
    return;
  }

  // Determine how much taller the activity section needs to be to enable scrolling it into view.
  const { top, bottom } = rootEl.getBoundingClientRect();
  const { top: parentTop, bottom: parentBottom } = rootEl.parentElement.getBoundingClientRect();
  const spaceAbove = top - parentTop;
  const spaceBelow = parentBottom - bottom;
  const spaceAround = spaceAbove + spaceBelow - 32; // Account for 16px margin top and bottom.

  // If more height is required to enable scrolling, add it to a spacer element below the activity.
  if (spaceAround > 0 && scrollSpacer.value) {
    scrollSpacer.value.style.height = `${spaceAround}px`;
  }

  root.value?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
};

defineExpose({ scrollIntoView });

const fieldNameMap = computed(() =>
  props.fieldsConfig.reduce<Record<string, string>>(
    (acc, field) => ({ ...acc, [field.name]: field.label }),
    {}
  )
);
</script>

<style lang="scss" scoped>
.activity-root {
  scroll-margin-bottom: 16px;
  scroll-margin-top: 16px;
}

.activity {
  margin-top: 12px;
}

.empty-message {
  padding: 24px;
  text-align: center;
}
</style>
