import { onUnmounted } from 'vue';

import type { ResourceMap } from '@/helpers/resource';
import { RestResource } from '@/helpers/resource';

/**
 * Simple wrapper for the RestResource class, which provides an single instance,
 * and which aborts all in-flight requests when the calling component is unmounted.
 */
export const useResource = <T extends keyof ResourceMap>(name: T) => {
  const resource = new RestResource<T>(name);

  onUnmounted(() => {
    resource._abort('component unmounted');
  });

  return resource;
};
