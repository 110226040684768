export const ctaOptions = [
  { label: 'Learn more', value: 'learn_more' },
  { label: 'Sign up', value: 'sign_up' },
  { label: 'Join now', value: 'join_now' },
  { label: 'Shop', value: 'shop' },
  { label: 'Get quotes', value: 'get_quotes' },
  { label: 'Start saving', value: 'start_saving' },
  { label: 'View', value: 'view' },
  { label: 'Get started', value: 'get_started' },
  { label: 'Apply now', value: 'apply_now' },
  { label: 'Shop now', value: 'shop_now' },
  { label: 'Call now', value: 'call_now' },
  { label: 'Claim now', value: 'claim_now' },
  { label: 'Add card', value: 'add_card' },
  { label: 'Read', value: 'read' },
  { label: 'View plans', value: 'view_plans' },
] as const;

export const ctaDefault = 'learn_more';
